import { Injectable } from '@angular/core';
import { BaseApiService } from '../../@core/services/base-api.service';
import { TabItemEnum } from '../../shared/enums/tab-item.enum';
import { Observable } from 'rxjs';

export enum TransactionSubState {
  Successful = 'successful',
  Expired = 'expired',
  Cancelled = 'cancelled',
  Rejected = 'rejected',
  Blacklisted = 'blacklisted',
  VelocityLimit = 'velocity-limit',
  SameWallet = 'same-wallet',
  AwaitingRefund = 'awaiting-refund',
  RefundTransfer = 'refund-transfer',
  Refunded = 'refunded',
  Pending = 'pending',
  Unconfirmed = 'unconfirmed',
  Received = 'received',
  InReview = 'in-review',
  Credited = 'credited',
  MoveToCreated = 'move-to-created',
}

export type DepositDetailsType = {
  id: string;
  brandId: string;
  brand: string;
  merchantId: string;
  operatorTransactionId: string;
  status: string;
  state: string;
  subState: string;
  amount: string;
  amountUsd: string;
  sourceAddress: string;
  currency: string;
  targetCurrency: string;
  customerId: string;
  exchangeRate: string;
  targetAddress: string;
  created: string;
  updated: string;
  amountCrypto: string;
  amountPayed: string;
  amountPayedCrypto: string;
  amountPayedUsd: string;
  chainalysisLog: string;
  internalDestinationTag: string;
}

export const TRANSACTION_SUB_STATE_TITLE_MAP = {
  [TransactionSubState.Successful]: 'Successful',
  [TransactionSubState.Expired]: 'Expired',
  [TransactionSubState.Cancelled]: 'Cancelled',
  [TransactionSubState.Rejected]: 'Rejected',
  [TransactionSubState.Blacklisted]: 'Blacklisted',
  [TransactionSubState.VelocityLimit]: 'Velocity limit',
  [TransactionSubState.SameWallet]: 'Same wallet',
  [TransactionSubState.AwaitingRefund]: 'Awaiting refund',
  [TransactionSubState.RefundTransfer]: 'Refund transfer',
  [TransactionSubState.Refunded]: 'Refunded',
  [TransactionSubState.Pending]: 'Pending',
  [TransactionSubState.Unconfirmed]: 'Unconfirmed',
  [TransactionSubState.Received]: 'Received',
  [TransactionSubState.InReview]: 'In review',
  [TransactionSubState.Credited]: 'Credited',
  [TransactionSubState.MoveToCreated]: 'Move to created',
};

@Injectable()
export class TransactionsHistoryService {
  orderedKeys = {
    deposits: [
      'id',
      'brandId',
      'operatorTransactionId',
      'status',
      'amount',
      'currency',
      'amountUsd',
      'customerId',
      'created',
      'updated',

      'amountCrypto',
      'targetCurrency',
      'exchangeRate',
      'targetAddress',

      'sourceAddress',
      'amountPayed',
      'amountPayedUsd',
      'amountPayedCrypto',
    ],
    withdrawals: [
      'id',
      'brandId',
      'operatorTransactionId',
      'status',
      'amount',
      'currency',
      'amountUsd',
      'customerId',
      'created',
      'updated',

      'amountCrypto',
      'targetCurrency',
      'exchangeRate',

      'blockchainTxId',
      'targetAddress',
      'intermediateAddress',
      'intermediateTxId',
    ],
  };

  constructor(private baseApi: BaseApiService) {
  }

  getTransactions(method: string, filter?: any) {
    return this.baseApi.get(`${method === TabItemEnum.Unmatched ? 'unmatched-transfers' : method}`, filter);
  }

  getTransactionDetails(method: string, id: number): Observable<DepositDetailsType> {
    return this.baseApi.get(`${method}/${id}`);
  }

  sendManualConfirmation(data: any, id: number) {
    return this.baseApi.post(`deposits/${id}/manualConfirmation`, data);
  }

  getByOrderTransactionDetails(method: string, keys: Array<string>) {
    const existOrderedkeys = this.orderedKeys[method].filter(orderedKey => keys.includes(orderedKey));
    const orderedKeys = keys
      .map((key, i) => {
        return existOrderedkeys[i];
      })
      .filter(d => d);
    const newKeys = keys.filter(key => !this.orderedKeys[method].includes(key));
    return [...orderedKeys, ...newKeys];
  }

  public matchDeposit(data: { id: string, hash: string, currency: string }) {
    const { id, ...rest } = data;
    return this.baseApi.put(`deposit/match/${id}`, rest);
  }
}
