import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'renameParamByMethod' })
export class RenameParamByMethod implements PipeTransform {
  transform(name: string, method: string = ''): string {
    if (method === 'withdrawals') {
      if (name === 'targetAddress') {
        return 'withdrawalsTargetAddress';
      } else if (name === 'destinationTag') {
        return 'Destination Tag';
      } else if (name === 'blockchainTxId') {
        return 'Blockchain Hash ID'
      } else if (name === 'operatorTransactionId') {
        return 'Operator ID'
      } else {
        return name;
      }
    } else if (method === 'deposits') {
      if (name === 'operatorTransactionId') {
        return 'Operator ID'
      } else {
        return name;
      }
    } else {
      return name;
    }
  }
}
