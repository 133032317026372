import { NgModule } from '@angular/core';
import { ChartsModule } from './charts/charts.module';
import { Page404Component } from './404/page-404.component';
import { ThemeModule } from '../@theme/theme.module';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
import { IsNotAuthGuardService } from './services/is-not-auth-guard.service';
import { ExportAsModule } from 'ngx-export-as';
import { TranslateModule } from '@ngx-translate/core';
import { PaginationComponent } from './pagination/pagination.component';
import { DialogWithInputComponent } from './components/dialog-with-input/dialog-with-input';
import { DialogConfirmComponent } from './components/dialog-confirm/dialog-confirm';
import { CurrenciesService } from './services/currencies.service';
import { GetCurrenciesResolver } from './resolvers/get-currencies.resolver';
import { PermissionsService } from './services/permissions.service';
import { GetPermissionsResolver } from './resolvers/get-permissions.resolver';
import { GetBrandsResolver } from './resolvers/get-brands.resolver';
import { ButtonTimeResetComponent } from './components/button-time-reset/button-time-reset.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { GetConfirmWalletsResolver } from './resolvers/get-confirm-wallets.resolver';
import { GetSaveWalletsResolver } from './resolvers/get-save-wallets.resolver';
import {GetUtcResolver} from './resolvers/get-utc.resolver';
import {UtcService} from './services/utc.service';
import {CopyBtnModule} from './components/copy-btn/copy-btn.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ColorSketchModule } from 'ngx-color/sketch';
import { ImgCropperComponent } from './components/img-cropper/img-cropper.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { ImgCropperService } from './components/img-cropper/img-cropper.service';
import { CommonModule } from '@angular/common';
import { CryptoTopupGuard } from './services/crypto-topup.guard';

@NgModule({
  imports: [
    ChartsModule,
    ThemeModule,
    ExportAsModule,
    TranslateModule,
    CopyBtnModule,
    ImageCropperModule,
    ColorSketchModule,
    ImageCropperModule,
    CommonModule,
  ],
  exports: [
    ChartsModule,
    ThemeModule,
    ExportAsModule,
    TranslateModule,
    PaginationComponent,
    DialogWithInputComponent,
    DialogConfirmComponent,
    ButtonTimeResetComponent,
    CopyBtnModule,
    ImgCropperComponent,
    ColorPickerComponent,
  ],
  declarations: [
    Page404Component,
    PaginationComponent,
    DialogWithInputComponent,
    DialogConfirmComponent,
    ButtonTimeResetComponent,
    MaintenanceComponent,
    ImgCropperComponent,
    ColorPickerComponent,
  ],
  providers: [
    AuthGuardService,
    IsNotAuthGuardService,
    AuthService,
    CurrenciesService,
    GetCurrenciesResolver,
    GetPermissionsResolver,
    GetBrandsResolver,
    GetConfirmWalletsResolver,
    GetSaveWalletsResolver,
    GetUtcResolver,
    PermissionsService,
    UtcService,
    ImgCropperService,
    CryptoTopupGuard,
  ],
})
export class SharedModule {}
