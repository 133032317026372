import { Injectable } from '@angular/core';

import { BaseApiService } from '../../@core/services/base-api.service';

import { CurrenciesInterface } from '../interfaces/currencies.interface';
import { BehaviorSubject, Observer, ReplaySubject } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { USDC_ERC20, USDT_ERC20, USDT_TRC20 } from '../constants/currency';

export interface CurrencyInterface {
  id: string;
  name: string;
}

@Injectable()
export class CurrenciesService {
  public crypto$ = new ReplaySubject<CurrencyInterface[]>();
  public fiat$ = new ReplaySubject<CurrencyInterface[]>();
  public currencies: CurrenciesInterface;
  public legendSelect = new BehaviorSubject<any>({});
  public legendSelectEvolution = new BehaviorSubject<any>({});

  constructor(private baseApi: BaseApiService) {
  }

  updateCurrencies(observer?: Observer<CurrenciesInterface>) {
    this.baseApi
      .get(`currencies`)
      .pipe(
        first(),
        tap((value: any) => {
          this.crypto$.next(value.crypto);
          this.fiat$.next(value.fiat);
        }),
      )
      .pipe(
        map(value => {
          // legacy
          return {
            crypto: value.crypto.map(i => i.id),
            fiat: value.fiat.map(i => i.id),
          };
        }),
      )
      .subscribe(observer);
  }

  public getCurrency(value: string): string {
    switch (value) {
      case USDT_ERC20:
        return 'USDT (ERC20)';
      case USDT_TRC20:
        return 'USDT (TRC20)';
      case USDC_ERC20:
        return 'USDC (ERC20)';
      default:
        return value.toUpperCase();
    }
  }
}
