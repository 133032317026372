export const BTC = 'btc';
export const LTC = 'ltc';
export const ETH = 'eth';
export const TRX = 'trx';
export const XRP = 'xrp';
export const USDT_ERC20 = 'usdt_erc20';
export const USDT_TRC20 = 'usdt_trc20';
export const USDC_ERC20 = 'usdc_erc20';
export const DOGE = 'doge';
export const BNB = 'bnb';
