import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class CryptoTopupGuard implements CanActivate {
  private isColdWalletFlow: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    ) {
    this.isColdWalletFlow = this.authService.currentUserValue.coldWalletFlowEnable;
  }

  public canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.isColdWalletFlow) {
      this.router.navigateByUrl('/not-found');
      return false;
    }
    return true;
  }
}
