import { Pipe, PipeTransform } from '@angular/core';
import { CurrenciesService } from '../../shared/services/currencies.service';

@Pipe({
  name: 'currencyFormat',
})
export class CurrencyFormatPipe implements PipeTransform {
  constructor(private currenciesService: CurrenciesService,) {
  }

  transform(value: string): string {
    return this.currenciesService.getCurrency(value);
  }
}
