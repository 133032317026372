import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import {
  NbActionsModule,
  NbCardModule,
  NbLayoutModule,
  NbMenuModule,
  NbRouteTabsetModule,
  NbSearchModule,
  NbSidebarModule,
  NbTabsetModule,
  NbThemeModule,
  NbUserModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbContextMenuModule,
  NbProgressBarModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  NbStepperModule,
  NbButtonModule,
  NbInputModule,
  NbAccordionModule,
  NbDialogModule,
  NbWindowModule,
  NbListModule,
  NbToastrModule,
  NbAlertModule,
  NbSpinnerModule,
  NbRadioModule,
  NbSelectModule,
  NbTooltipModule,
  NbDatepickerModule,
  NbIconModule,
} from '@nebular/theme';

import { NbSecurityModule } from '@nebular/security';
import { NbMomentDateModule } from '@nebular/moment';

import { HeaderComponent, SearchInputComponent } from './components';
import {
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  EvaIconsPipe,
  NamePipe,
  StringWithoutDashPipe,
  RenameParamByMethod,
  SubStateValuePipe,
  CurrencyFormatPipe,
} from './pipes';
import { MainboardLayoutComponent, AuthLayoutComponent } from './layouts';
import { LoadingService } from './services/loading.service';
import { InputNumberDirective, StyleDirective, ButtonSpinnerDirective } from './directives';
import { KeyInCamelCaseToTitlePipe } from './pipes';
import { CustomDatePipe } from './pipes/date.pipe';
import { CoinPipe } from './pipes/coin.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { NbEvaIconsModule } from '@nebular/eva-icons';

const BASE_MODULES = [CommonModule, FormsModule, ReactiveFormsModule];

const NB_MODULES = [
  NbCardModule,
  NbLayoutModule,
  NbTabsetModule,
  NbRouteTabsetModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbContextMenuModule,
  NgbModule,
  NbSecurityModule, // *nbIsGranted directive,
  NbProgressBarModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  NbStepperModule,
  NbButtonModule,
  NbListModule,
  NbToastrModule,
  NbInputModule,
  NbAccordionModule,
  NbDialogModule,
  NbWindowModule,
  NbAlertModule,
  NbSpinnerModule,
  NbRadioModule,
  NbSelectModule,
  NbTooltipModule,
  NbDatepickerModule,
  NbMomentDateModule,
  NbEvaIconsModule,
  NbIconModule,
];

const COMPONENTS = [HeaderComponent, SearchInputComponent, MainboardLayoutComponent, AuthLayoutComponent];

const ENTRY_COMPONENTS = [];

const DIRECTIVES = [InputNumberDirective, StyleDirective, ButtonSpinnerDirective];

const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  EvaIconsPipe,
  NamePipe,
  StringWithoutDashPipe,
  RenameParamByMethod,
  KeyInCamelCaseToTitlePipe,
  CustomDatePipe,
  CoinPipe,
  SafeHtmlPipe,
  SubStateValuePipe,
  CurrencyFormatPipe,
];

const NB_THEME_PROVIDERS = [
  ...NbThemeModule.forRoot({
    name: 'default',
  }).providers,
  ...NbSidebarModule.forRoot().providers,
  ...NbMenuModule.forRoot().providers,
  ...NbDialogModule.forRoot().providers,
  ...NbWindowModule.forRoot().providers,
  ...NbToastrModule.forRoot().providers,
  ...NbDatepickerModule.forRoot().providers,
];

@NgModule({
  imports: [...BASE_MODULES, ...NB_MODULES],
  exports: [...BASE_MODULES, ...NB_MODULES, ...COMPONENTS, ...PIPES, ...DIRECTIVES],
  declarations: [...COMPONENTS, ...PIPES, ...DIRECTIVES],
  entryComponents: [...ENTRY_COMPONENTS],
  providers: [LoadingService],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: ThemeModule,
      providers: [...NB_THEME_PROVIDERS],
    };
  }
}
